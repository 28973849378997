<template>
  <div>
    <b-form-group
      label="Ödeme Tipi"
      label-for="payment_type"
    >
      <validation-provider
        #default="{ errors }"
        name="Ödeme Tipi"
        rules="required"
      >
        <b-form-radio-group
          v-model="tracking.payment_type"
          :options="options"
          class="demo-inline-spacing"
          name="payment_type"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'DamagePaymentType',
  components: {
    BFormGroup,
    BFormRadioGroup,
    ValidationProvider,
  },
  data() {
    return {
      options: [
        { text: 'Sigorta Ödemeli', value: 0 },
        { text: 'Müşteri Ödemeli', value: 1 },
      ],
    }
  },
  computed: {
    tracking() {
      return this.$store.getters['damageTracking/getData']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
