<template>
  <b-form-group
    label="Ekspertiz Bedeli"
    label-for="expertise_price"
  >
    <b-input-group
      prepend="₺"
      append=",00"
    >
      <cleave
        id="expertise_price"
        v-model="tracking.expertise_price"
        class="form-control"
        :raw="true"
        :options="options.price"
        placeholder="Ekspertiz Bedeli"
      />
    </b-input-group>
  </b-form-group>

</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
  BInputGroup,

} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'DamageExpertisePrice',
  components: {
    BFormGroup,
    BInputGroup,
    Cleave,

  },
  data() {
    return {
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    tracking() {
      return this.$store.getters['damageTracking/getData']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped></style>
