<template>
  <b-form-group
    label="Sigorta Şirketi"
    label-for="id_com_damage_tracking_insurance"
  >
    <validation-provider
      #default="{ errors }"
      name="Sigorta Şirketi"
      rules="required"
    >
      <v-select
        id="id_com_damage_tracking_insurance"
        v-model="tracking.id_com_damage_tracking_insurance"
        label="title"
        :reduce="item => item.id"
        :options="trackingInsurance"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'TrackingInsurance',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    tracking() {
      return this.$store.getters['damageTracking/getData']
    },
    trackingInsurance() {
      return this.$store.getters['damageTrackingInsurance/getDataList']
    },
  },
  created() {
    this.getTrackingInsurance()
    localize('tr')
  },
  methods: {
    getTrackingInsurance() {
      this.$store.dispatch('damageTrackingInsurance/dataList', {
        select: [
          'com_damage_tracking_insurance.id AS id',
          'com_damage_tracking_insurance.title AS title',
        ],
        order_by: [
          'com_damage_tracking_insurance.id', 'ASC',
        ],
      })
    },
  },
}
</script>
