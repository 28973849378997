import { render, staticRenderFns } from "./VipBox.vue?vue&type=template&id=34e9a140&"
import script from "./VipBox.vue?vue&type=script&lang=js&"
export * from "./VipBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports