<template>
  <div>
    <b-form-group
      label="Onarım Başlangıç Tarihi"
      label-for="entry_date"
    >
      <validation-provider
        #default="{ errors }"
        name="Onarım Başlangıç Tarihi"
        rules="required"
      >
        <b-form-datepicker
          id="repair_date"
          v-model="tracking.repair_date"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import {

  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'DamageRepairDate',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  computed: {
    tracking() {
      return this.$store.getters['damageTracking/getData']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
