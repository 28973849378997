<template>
  <div>
    <b-form-group
      label="Vip Araç"
      label-for="vip"
    >
      <b-form-checkbox
        v-model="tracking.vip"
        name="Vip"
        inline
        switch
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'

export default {
  name: 'DamageVipBox',
  components: {
    BFormCheckbox,
    BFormGroup,
  },
  computed: {
    tracking() {
      return this.$store.getters['damageTracking/getData']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
