<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <customer-cars />
        </b-card>
      </b-col>
      <b-col v-if="tracking.id_com_cars">
        <b-card title="Hasar Takibi Kaydı">
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <damage-tracking-no-card />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <damage-document-no-card />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <location-card />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <damage-entry-date />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <damage-release-date />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <damage-expertise-date />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <damage-expertise-name-card />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <damage-payment-type />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <damage-expertise-price />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <tracking-type />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <damage-repair-date />
            </b-col>
            <b-col
              v-if="tracking.payment_type === '0' || tracking.payment_type === 0"
            >
              <tracking-insurance />
            </b-col>
            <b-col cols="12">
              <damage-vip-box />
            </b-col>
          </b-row>
        </b-card>
        <save-button :action-methods="saveData" />
      </b-col>
    </b-row>
  </validation-observer>

</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CustomerCars from '@/views/Damage_tracking/elements/CustomerCars.vue'
import SaveButton from '@/views/Damage_tracking/elements/SaveButton.vue'
import DamageEntryDate from '@/views/Damage_tracking/elements/EntryDate.vue'
import DamageReleaseDate from '@/views/Damage_tracking/elements/ReleaseDate.vue'
import DamagePaymentType from '@/views/Damage_tracking/elements/PaymentType.vue'
import DamageExpertisePrice from '@/views/Damage_tracking/elements/expertise_price.vue'
import DamageVipBox from '@/views/Damage_tracking/elements/VipBox.vue'
import TrackingType from '@/views/Damage_tracking/elements/TrackingType.vue'
import TrackingInsurance from '@/views/Damage_tracking/elements/TrackingInsurance.vue'
import LocationCard from '@/views/Damage_tracking/elements/LocationCard.vue'
import DamageTrackingNoCard from '@/views/Damage_tracking/elements/TrackingNo.vue'
import DamageDocumentNoCard from '@/views/Damage_tracking/elements/DocumentNo.vue'
import DamageExpertiseNameCard from '@/views/Damage_tracking/elements/ExpertiseName.vue'
import DamageExpertiseDate from '@/views/Damage_tracking/elements/ExpertiseDate.vue'
import DamageRepairDate from '@/views/Damage_tracking/elements/RepairDate.vue'

export default {
  name: 'TrackingForm',
  components: {
    DamageRepairDate,
    DamageExpertiseDate,
    DamageExpertiseNameCard,
    DamageDocumentNoCard,
    DamageTrackingNoCard,
    LocationCard,
    TrackingInsurance,
    TrackingType,
    DamageVipBox,
    DamageExpertisePrice,
    DamagePaymentType,
    DamageReleaseDate,
    DamageEntryDate,
    BRow,
    BCol,
    BCard,
    CustomerCars,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    tracking() {
      return this.$store.getters['damageTracking/getData']
    },
    saveStatus() {
      return this.$store.getters['damageTracking/saveDataStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push(`/damage-tracking/view/${val.id}`)
        this.$store.commit('damageTracking/SET_DATA_RESET')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.tracking.submitStatus = false
    },
  },
  created() {
    this.$store.commit('damageTracking/SET_DATA_RESET')
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.tracking.submitStatus = true
          // if (this.tracking.payment_type === '1' || this.tracking.payment_type === 1) {
          //   this.tracking.id_com_damage_tracking_insurance = 0 ??
          // }
          this.tracking.id_com_customer = this.$route.params.id
          this.tracking.id_com_damage_tracking_status = 27
          this.$store.dispatch('damageTracking/saveData', this.tracking)
        }
      })
    },
  },
}
</script>
